import { Article, ArticleContent, Category } from '../backend/models';
import { generateSlugFrom } from './news';

export function makeUrlForArticleContent(locale: string, articleContent: ArticleContent | string) {
    const parts = [];

    if (typeof articleContent === 'string') {
        parts.push(articleContent);
    } else {
        parts.push(articleContent.slug);
    }

    parts.push('article');
    parts.push(locale);

    return '/' + parts.reverse().join('/');
}

export function makeUrlForArticle(locale: string, article: Article, opts?: { isEmbedMode?: boolean, isNoNavMode?: boolean; }) {
    const parts = [];

    parts.push(article.publishedContent.slug);

    parts.push('article');
    parts.push(locale);

    let query = '';
    let queryParams = [];

    if (opts?.isEmbedMode) {
        queryParams.push('embed=1');
    }
    if (opts?.isNoNavMode) {
        queryParams.push('nonav=1');
    }
    if (queryParams.length > 0) {
        query = '?' + queryParams.join('&');
    }

    // if (Router.query) {
    //     query = '&' + Router.query.toString()
    // }

    return '/' + parts.reverse().join('/') + query;
}

export function makeUrlForCategory(locale: string, category: Category | string, opts?: { isEmbedMode?: boolean, isNoNavMode?: boolean; }) {
    const parts = [];

    if (typeof category === 'string') {
        parts.push(category);
    } else {
        parts.push(category.slug);
    }

    parts.push('category');
    parts.push(locale);

    let query = '';
    let queryParams = [];

    if (opts?.isEmbedMode) {
        queryParams.push('embed=1');
    }
    if (opts?.isNoNavMode) {
        queryParams.push('nonav=1');
    }
    if (queryParams.length > 0) {
        query = '?' + queryParams.join('&');
    }

    return '/' + parts.reverse().join('/') + query;
}

export function makeDefaultUrlForCategory(locale: string, category: Category, allArticles: Array<Article>, opts?: { isEmbedMode?: boolean, isNoNavMode?: boolean; }, isFlagOn?: boolean) {
    if (isFlagOn && category.slug && category.slug.trim() != "") {
        return makeUrlForCategory(locale, category, opts);
    }

    const sorter = (a: Article, b: Article) => {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
    };

    const [defaultArticle] = allArticles.filter(a => a.categoryId == category.id).sort(sorter);

    if (!defaultArticle) return undefined;

    return makeUrlForArticle(locale, defaultArticle, opts);
}

export function validateEmail(email: string) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}


export function makeUrlForNews(locale: string, newsId: bigint, stripedTitle: string) {
    return `/${locale}/news/${newsId}-${generateSlugFrom(stripedTitle)}`;
}