import Link from 'next/link';
import { useContext, useEffect, useRef, useState } from 'react';
import { Article, ArticleContent, Category } from '../backend/models';
import { AppContext } from '../frontend/AppContext';
import { KbContext } from '../frontend/KbContext';
import { TreelizedCategory } from '../utils/treelizeCategories';
import { makeUrlForArticle, makeUrlForCategory } from '../utils/urlizer';
import { isFlagOn } from '../utils/flags';

export function ArticlesMenu(props: { categories: Array<TreelizedCategory>, articles: Array<Article>, selectedArticleContent?: ArticleContent, selectedCategory?: Category, onArticleSelected?: () => void }) {
    const getArticlesForCategory = (catId: bigint | null) => {
        return props.articles.filter(a => a.categoryId == catId && a.publishedContent).sort(articleSorter);
    }

    const selectedArticleCategoryId = props.articles.filter(i => i.publishedContent?.id == props.selectedArticleContent?.id)[0]?.categoryId;

    const hasCatAndSubcatsAnyArticles = (catId: bigint | null) => {
        const [catWithSubCats] = props.categories.filter(i => i.id == catId);

        const mainCatCount = props.articles.filter(a => a.categoryId == catWithSubCats.id && a.publishedContent).length;
        if (mainCatCount > 0) return true;

        if (catWithSubCats.children) {
            const subCatIds = catWithSubCats.children.map(i => i.id);

            const subLen = props.articles.filter(a => subCatIds.includes(a.categoryId as bigint) && a.publishedContent).length;

            return subLen > 0;
        } else {
            return false;
        }
    }

    const renderArticleLink = (article: Article) => {
        return <ArticleLink
            onClick={props.onArticleSelected}
            key={article.publishedContent.id.toString()}
            article={article}
            isSelected={!!props.selectedArticleContent && article.publishedContent.id == props.selectedArticleContent.id} />;
    }

    useEffect(() => {
        if (typeof document != 'undefined') {
            setTimeout(() => {
                const el = document.querySelector('.article-menu-link-' + props.selectedArticleContent?.id);
                if (el) {
                    el.scrollIntoView();
                }
            }, 100);
        }
    }, []);

    return <div className='px-5'>
        {/* <div className='mb-5'>
            <ArticleDetailSearchField />
        </div> */}

        <div className='flex flex-col space-y-2 text-sm articles-menu'>
            {getArticlesForCategory(null).map(i => renderArticleLink(i))}
            {props.categories.map(cat => {
                const articlesInCat = getArticlesForCategory(cat.id);
                const hasSubArticles = hasCatAndSubcatsAnyArticles(cat.id);
                if (!hasSubArticles) return null;

                const isAnySubArticleSelected = selectedArticleCategoryId == cat.id || cat.children.some(i => i.id == selectedArticleCategoryId);
                const isCategorySelected = props.selectedCategory?.id == cat.id
                const isSubCategorySelected = props.selectedCategory?.parentCategoryId == cat.id
                return <ArticleCategory key={cat.id.toString()} category={cat} isCollapsed={(isAnySubArticleSelected || isCategorySelected || isSubCategorySelected) ? false : cat.isCollapsedByDefault} isSelected={props.selectedCategory?.id == cat.id}>
                    {articlesInCat.map(i => renderArticleLink(i))}

                    {cat.children.map(subCat => {
                        const articlesInSubCat = getArticlesForCategory(subCat.id);
                        if (articlesInSubCat.length == 0) return null;

                        return <ArticleCategory key={subCat.id.toString()} category={subCat} isCollapsed={!articlesInSubCat.some(i => i.publishedContent.id == props.selectedArticleContent?.id || i.categoryId == props.selectedCategory?.id)} isSelected={props.selectedCategory?.id == subCat.id}>
                            {articlesInSubCat.map(i => renderArticleLink(i))}
                        </ArticleCategory>
                    })}
                </ArticleCategory>
            }
            )}
        </div>
    </div>
}

function ArticleCategory(props: { category: Category, isCollapsed?: boolean, children: React.ReactNode, isSelected?: boolean }) {
    const [isExpanded, setIsExpaded] = useState(!props.isCollapsed);
    const context = useContext(KbContext);
    const appContext = useContext(AppContext);
    
    if (!context || !appContext) {
        return null;
    }
    return <div className={`article-menu-category`}>
        {(isFlagOn('kb_lcat', appContext.tenant.accountInfo) && props.category.slug && props.category.slug.trim() !== "") ? <div className={`pl-1 py-1  ${props.isSelected ? 'text-primary-bg' : ''}`}>
            <span onClick={() => setIsExpaded(oldVal => !oldVal)} className={`cursor-pointer inline-block transition-all hover:text-primary-bg ${isExpanded ? 'rotate-90' : 'rotate-0'}`}><i className="fa-solid fa-chevron-right"></i></span>
            <Link href={makeUrlForCategory(appContext.tenant.currentLocale, props.category, {
                isEmbedMode: appContext.tenant.isEmbedMode,
                isNoNavMode: appContext.tenant.isNoNavMode
            })}><span onClick={() => setIsExpaded(true)} className={`ltr:ml-2 rtl:mr-2 font-semibold cursor-pointer hover:text-primary-bg`}>{props.category.title}</span></Link>
        </div>
            :
            <div onClick={() => setIsExpaded(oldVal => !oldVal)} className={`cursor-pointer pl-1 py-1  ${props.isSelected ? 'active text-primary-bg' : ''}`}>
                <span className={`inline-block transition-all ${isExpanded ? 'rotate-90' : 'rotate-0'}`}><i className="fa-solid fa-chevron-right"></i></span>
                <span className={`ltr:ml-2 rtl:mr-2 font-semibold`}>{props.category.title}</span>
            </div>
        }

        <div className={`flex flex-col ml-4 space-y-2 mt-2 transition-all ${isExpanded ? '' : 'hidden'}`}>
            {props.children}
        </div>
    </div>
}

function ArticleLink(props: { article: Article, isSelected: boolean, onClick?: () => void }) {
    const context = useContext(KbContext);
    const appContext = useContext(AppContext);

    if (!context || !appContext) {
        return null;
    }

    return <Link href={makeUrlForArticle(appContext.tenant.currentLocale, props.article, {
        isEmbedMode: appContext.tenant.isEmbedMode,
        isNoNavMode: appContext.tenant.isNoNavMode
    })}>
        <a onClick={props.onClick} className={`article-menu-link-${props.article?.publishedContent?.id} ${props.isSelected ? 'active text-primary-bg' : ''}
                    ${!props.isSelected ? 'hover:text-primary-bg' : ''}
                    pl-1
                    py-1
                    article-menu-item`}>
            {props.article.publishedContent.title}
        </a>
    </Link >
}

function articleSorter(a: Article, b: Article) {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;

    return 0;
}