import { useRouter } from 'next/router'
import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext, useAuthContext } from '../frontend/AppContext';
import Link from 'next/link'
import { ArticleDetailSearchField } from './SearchFields';
import { ArticlesMenu } from './ArticlesMenu';
import { KbContext } from '../frontend/KbContext';
import { isInPricingPlan } from '../utils/flags';
import clsx from 'clsx';
import useIsSafari from '../hooks/useIsSafari';

export function MainMenu(props: { hasSearch?: boolean, variant?: 'dark' | 'transparent' }) {
    const context = useContext(AppContext);
    const kbContext = useContext(KbContext);
    const authCtx = useAuthContext();

    const curLng = context?.tenant?.currentLocale || '';
    const hasNewsAvailable = isInPricingPlan('ann_v2npv', context?.tenant?.accountInfo);

    const [isMobileMenuShown, setIsMobileMenuShown] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuShown(o => !o);
    }

    useEffect(() => {
        if (isMobileMenuShown && typeof window !== 'undefined') {
            document.body.style.overflow = 'hidden';
        } else if (!isMobileMenuShown && typeof window !== 'undefined') {
            document.body.style.overflow = '';
        }
    }, [isMobileMenuShown]);

    const isSafary = useIsSafari();
    
    return <div
        className={`main-menu 
                        flex
                        items-center
                        justify-center
                        w-full
                        p-3 
                        px-5
                        lg:px-10
                        shadow-softer
                        border-b-gray-borderlike
                        border-b
                        bg-white
                    `}>
        <div className='flex max-w-[90rem] items-center flex-grow desktop-area'>
            <div className={`${!isSafary && "flex-shrink-0"}`}>
                <Link href={`/${curLng}`}>
                    <a>
                        {context?.tenant.icons.logoFileId && <img src={'https://cdn-assets.productfruits.com/' + context?.tenant.icons.logoFileId}
                            className={`${props.hasSearch ? 'min-w-[40px]' : ''} max-h-[2.5rem] w-full object-contain object-left`} 
                            style={{ maxHeight: '40px', width: '100%' }} />}
                        {!context?.tenant.icons.logoFileId && <span className='text-xl font-bold'>Your Logo</span>}
                    </a>
                </Link>
            </div>

            {props.hasSearch &&
                <ArticleDetailSearchField className='ml-10 mr-10' />
            }

            {!props.hasSearch && <div className='w-full'></div>}

            <div className='flex space-x-10 text-primary-bg flex-shrink-0'>
                <MainMenuLink text={context?.tenant.texts.helpCenterLinkText || ''} url={`/${curLng}`} additinalActiveRoutes={[`${curLng}/search`]} icon='fa-solid fa-book' additClass='menu-item-contact-us' />
                {hasNewsAvailable && context?.tenant.announcements?.enabled && <MainMenuLink text={context?.tenant.texts.announcementsMenuLinkText} url={`/${curLng}/news`} icon='fa-solid fa-bullhorn' additClass='menu-item-news' />}
                {context?.tenant.menuItems?.map(mi => <MainMenuLink key={mi.url + mi.title} text={mi.title} url={mi.url} icon='fa-solid fa-up-right-from-square' />)}

                {
                    context?.auth?.isAnyMethodEnabled && <div className='flex my-auto mx-auto'><UserDropDown /></div>
                }

            </div>
        </div>
        <div className='mobile-area w-full'>
            <div className='flex items-center'>
                <div className='mr-5'>
                    <button onClick={() => toggleMobileMenu()}>
                        <i className="fas fa-bars"></i>
                    </button>
                </div>
                <div className='grow ml-2 mr-2'>
                    <ArticleDetailSearchField />
                </div>
            </div>

            <div className={`bg-white p-4 w-full fixed z-10 top-0 bottom-0 left-0 right-0 overflow-auto transition-transform ${isMobileMenuShown ? '' : '-translate-x-full'}`}>
                <div className='fixed z-20 right-[18px] top-[10px] text-lg'>
                    <button onClick={() => toggleMobileMenu()}><i className="fas fa-times"></i></button>
                </div>
                <div className='mt-6'>
                    <div className='flex text-sm flex-wrap'>
                        <MainMenuLink text={context?.tenant.texts.helpCenterLinkText || ''} url={`/${curLng}`} additinalActiveRoutes={[`${curLng}/search`]} icon='fa-solid fa-book' grow={true} additClass='menu-item-contact-us menu-item-contact-mobile' />
                        {hasNewsAvailable && context?.tenant.announcements?.enabled && <MainMenuLink text={context?.tenant.texts.announcementsMenuLinkText} url={`/${curLng}/news`} icon='fa-solid fa-bullhorn' additClass='menu-item-news' />}
                        {context?.tenant.menuItems?.map(mi => <MainMenuLink key={mi.url + mi.title} text={mi.title} url={mi.url} icon='fa-solid fa-up-right-from-square' grow={true} />)}

                        {authCtx.auth?.isAnyMethodEnabled && <UserMobile />}
                    </div>

                    {isMobileMenuShown && kbContext && <div className='mt-8'>
                        <ArticlesMenu articles={kbContext?.allArticles} categories={kbContext?.treelizedCategories} onArticleSelected={() => toggleMobileMenu()} />
                    </div>}
                </div>
            </div>
        </div>
    </div >
}

function MainMenuLink(props: { text: string, url: string, additinalActiveRoutes?: Array<string>, icon?: string, grow?: boolean, additClass?: string }) {
    const router = useRouter();

    const shouldBeActive = () => {
        if (router.pathname === props.url) return true;

        if (props.additinalActiveRoutes) {
            return props.additinalActiveRoutes.indexOf(router.pathname) !== -1;
        }

        return false;
    }

    return <div className='flex space-x-2'> <a href={props.url}
        className={`shrink-0 block p-3 hover:border-b-primary-bg ${shouldBeActive() ? 'border-b-2 border-b-primary-bg' : ''} ${props.grow ? 'grow' : ''} ${props.additClass || ''}`}>
        {props.icon && <span className='ltr:mr-2 rtl:ml-2'><i className={props.icon}></i></span>}
        {props.text}
    </a>

    </div>
}

function resolveAuthPath(IsAuthenticated?: boolean | null | undefined, lang?: string | undefined) {
    if (IsAuthenticated) {
        return `${lang ? `/${lang}/` : ""}/auth/sign-out`;
    } else {
        if (typeof window !== "undefined") {
            return `${lang ? `/${lang}/` : ""}/auth/signin/?return=${window.location.pathname}`
        } else {
            return `${lang ? `/${lang}/` : ""}/auth/signin`;
        }
    }
}

function UserMobile() {
    const router = useRouter();

    const authCtx = useAuthContext();

    const context = useContext(AppContext);

    const curLng = context?.tenant?.currentLocale || '';

    const handleNavigate = useCallback(
        (e: any) => {

            e.preventDefault();

            router.push(resolveAuthPath(authCtx.auth?.isAuthenticated, curLng))
        },
        [authCtx],
    )

    return <div className='flex space-x-2'> <a onClick={handleNavigate}
        className={clsx("shrink-0 flex p-3 hover:border-b-primary-bg space-x-2",
            "select-none cursor-pointer")}>
        <i className="fa fa-user mx-auto my-auto rtl:ml-2" aria-hidden="true"></i>
        <div>
            {
                authCtx?.auth?.isAuthenticated ?
                    context?.tenant.texts.authSignOut ?? "Sign out" :
                    context?.tenant.texts.authSignIn ?? "Sign in"
            }
        </div>
    </a>

    </div>
}

function UserDropDown() {

    const router = useRouter();

    const context = useContext(AppContext);

    const authCtx = useAuthContext();

    const curLng = context?.tenant?.currentLocale || '';

    const handleNavigate = useCallback(
        (e: any) => {

            e.preventDefault();

            router.push(resolveAuthPath(authCtx.auth?.isAuthenticated, curLng))
        },
        [authCtx],
    )

    return <div className='flex space-x-2'>
        <div className="dropdown inline-block relative">
            <button className={clsx("font-semibold flex mx-auto ",
                "items-center rounded-full shadow-sm w-7 h-7",
                "my-auto border-primary-bg")}>
                <i className="fa fa-user mx-auto my-auto" aria-hidden="true"></i>

            </button>
            <ul className={clsx("dropdown-menu text-md absolute hidden ml-12 -mt-3",
                "-translate-x-full rtl:translate-x-1/2 text-gray-700 pt-1 p-2 w-52")}>

                <li className="p-2 bg-white rounded-sm shadow-md mt-2 select-none cursor-pointer">
                    <a
                        onClick={handleNavigate}
                        className="hover:bg-gray-100 space-x-2 py-2 px-4 block whitespace-no-wrap">
                        <i className="fa fa-sign-in rtl:ml-2" aria-hidden="true"></i>
                        <span>
                            {
                                authCtx?.auth?.isAuthenticated ?
                                    context?.tenant.texts.authSignOut ?? "Sign out" :
                                    context?.tenant.texts.authSignIn ?? "Sign in"
                            }
                        </span>
                    </a>
                </li>
            </ul>
        </div>
        {/* {
            authCtx?.auth?.isAuthenticated && <div className='text-xs my-auto flex'>{authCtx.user?.email}</div>
        } */}
    </div>
}